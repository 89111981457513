import { gql } from "urql";

export const GetVendorServiceRandom = gql`
  query GetVendorServiceRandom($page: Int, $pageSize: Int) {
    getVendorServiceRandom {
      id
      interests
      vendors_id
      vendor_service_category_id
      service_name
      pinned
      description
      showcase(page: $page, pageSize: $pageSize) {
        id
        vendors_id
        vendor_service_category_id
        vendor_service_id
        media
      }
    }
  }
`;

export const GetVendorServiceByVendor = gql`
  query GetVendorServiceByVendor(
    $vendorsId: UUID!
    $page: Int
    $pageSize: Int
  ) {
    getVendorServiceByVendor(vendorsId: $vendorsId) {
      id
      interests
      vendors_id
      vendor_service_category_id
      service_name
      pinned
      description
      showcase(page: $page, pageSize: $pageSize) {
        id
        vendors_id
        vendor_service_category_id
        vendor_service_id
        media
      }
      phone_number
      email
      address
    }
  }
`;

export const GetMyVendorsServices = gql`
  query GetMyVendorServices($page: Int, $pageSize: Int) {
    getMyVendorServices {
      id
      interests
      vendors_id
      vendor_service_category_id
      service_name
      price {
        currency
        amount
      }
      pinned
      description
      phone_number
      email
      address
      showcase(page: $page, pageSize: $pageSize) {
        id
        vendors_id
        vendor_service_category_id
        vendor_service_id
        name
        media
        description
        tags
      }
    }
  }
`;

export const GetPinnedVendorServices = gql`
  query GetPinnedVendorServices($page: Int, $pageSize: Int) {
    getPinnedVendorServices {
      id
      interests
      vendor_service_category_id
      vendors_id
      service_name
      pinned
      description
      category {
        id
        Name
      }
      showcase(page: $page, pageSize: $pageSize) {
        id
        vendors_id
        vendor_service_category_id
        vendor_service_id
        name
        media
        description
      }
    }
  }
`;

export const GetVendorServiceBySearch = gql`
  query GetVendorServiceBySearch(
    $searchTerm: String!
    $page: Int
    $pageSize: Int
  ) {
    getVendorServiceBySearch(searchTerm: $searchTerm) {
      id
      interests
      vendors_id
      price {
        currency
        amount
      }
      rating
      vendor_service_category_id
      category {
        Name
        id
      }
      service_name
      pinned
      booked
      description
      phone_number
      email
      address
      showcase(page: $page, pageSize: $pageSize) {
        id
        vendors_id
        vendor_service_category_id
        vendor_service_id
        media
      }
      created_at
      updated_at
    }
  }
`;

export const GetMyVendorProfile = gql`
  query GetMyVendorProfile($page: Int, $pageSize: Int) {
    getMyVendorProfile {
      id
      user_uuid
      brand_name
      description
      logo
      phone_number
      email
      address
      created_at
      updated_at
      services {
        id
        interests
        vendor_service_category_id
        vendors_id
        category {
          Name
          id
        }
        service_name
        showcase(page: $page, pageSize: $pageSize) {
          id
          media
          vendor_service_id
          vendor_service_category_id
          vendors_id
        }
        description
      }
    }
  }
`;

export const GetVendorServiceByID = gql`
  query GetVendorServiceByID(
    $vendorServiceId: UUID!
    $page: Int
    $pageSize: Int
  ) {
    getVendorServiceByID(vendorServiceId: $vendorServiceId) {
      id
      interests
      vendors_id
      price {
        currency
        amount
      }
      vendor_service_category_id
      category {
        id
        Name
      }
      service_name
      pinned
      description
      email
      address
      showcase(page: $page, pageSize: $pageSize) {
        id
        vendors_id
        vendor_service_category_id
        vendor_service_id
        media
      }
      phone_number
      booked
      rating
    }
  }
`;

export const GetMyVendorBooking = gql`
  query GetMyVendorBooking($page: Int, $pageSize: Int) {
    getMyVendorBooking {
      vendor_Service {
        id
        interests
        vendors_id
        price {
          currency
          amount
        }
        rating
        vendor_service_category_id
        category {
          id
          Name
        }
        service_name
        pinned
        booked
        description
        phone_number
        email
        address
        showcase(page: $page, pageSize: $pageSize) {
          id
          vendors_id
          vendor_service_category_id
          vendor_service_id
          name
          media
        }
      }
      id
      user_uuid
      event_uuid
      vendors_id
      vendor_service_id
      booking_status
      date
    }
  }
`;

export const GetPinnedVendorServices2 = gql`
  query GetPinnedVendorServices2($page: Int, $pageSize: Int) {
    getPinnedVendorServices2 {
      id
      name
      description
      services {
        id
        service_name
        interests
        vendors_id
        price {
          currency
          amount
        }
        rating
        category {
          id
          Name
        }
        pinned
        booked
        showcase(page: $page, pageSize: $pageSize) {
          id
          vendors_id
          vendor_service_id
          vendor_service_category_id
          media
        }
      }
      images
    }
  }
`;
